<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/project-team' }">
        <app-i18n code="entities.projectTeam.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n
          code="entities.projectTeam.edit.title"
          v-if="isEditing"
        ></app-i18n>
        <app-i18n
          code="entities.projectTeam.new.title"
          v-if="!isEditing"
        ></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n
          code="entities.projectTeam.edit.title"
          v-if="isEditing"
        ></app-i18n>
        <app-i18n
          code="entities.projectTeam.new.title"
          v-if="!isEditing"
        ></app-i18n>
      </h1>
      <div
        class="app-page-spinner"
        v-if="findLoading"
        v-loading="findLoading"
      ></div>
      
      <h4>
        <app-i18n
          code="entities.projectManager.list.title"
        ></app-i18n>
      </h4>
      <app-project-manager-list-table
        :isInProjectTeam="true"
      ></app-project-manager-list-table>
      <br />
      <h4>
        <app-i18n code="entities.teamMember.list.title"></app-i18n>
      </h4>
      <app-team-member-list-table
        :isInProjectTeam="true"
      ></app-team-member-list-table>
    </div>
  </div>
</template>

   
  

<script>
import { mapGetters, mapActions } from 'vuex';
import ProjectTeamForm from '@/modules/project-team/components/project-team-form';
import TeamMemeberListTable from '@/modules/team-member/components/team-member-list-table';
import ProjectManagerListTable from '@/modules/project-manager/components/project-manager-list-table';
import { routerAsync } from '@/app-module';
import { EventBus } from '@/eventBus';

export default {
  name: 'app-project-team-form-page',

  props: ['id'],

  components: {
    [ProjectTeamForm.name]: ProjectTeamForm,
    [TeamMemeberListTable.name]: TeamMemeberListTable,
    [ProjectManagerListTable.name]: ProjectManagerListTable,
  },

  computed: {
    ...mapGetters({
      record: 'projectTeam/form/record',
      findLoading: 'projectTeam/form/findLoading',
      saveLoading: 'projectTeam/form/saveLoading',
      projectTeamId: 'projectTeam/form/projectTeamId',
      teamMembers: 'projectTeam/form/teamMembers',
      projectManagers: 'projectTeam/form/projectManagers',
      projectID: 'projectTeam/form/projectID',
    }),

    isEditing() {
      return !!this.id;
    },
  },

  async created() {
    if (this.isEditing) {
      await this.doFind(this.id);
    }
    await this.doFilterTeamMembers(this.teamMembers);
    await this.doFilterProjectManagers(this.projectManagers);

    EventBus.$on(
      'updateCooperationPartenerContractsList',
      (payload) => {
        this.doFilterTeamMembers(payload);
      },
    );

    EventBus.$on('updateEmploymentContractsList', (payload) => {
      this.doFilterProjectManagers(payload);
    });
  },

  methods: {
    ...mapActions({
      doFind: 'projectTeam/form/doFind',
      doNew: 'projectTeam/form/doNew',
      doUpdate: 'projectTeam/form/doUpdate',
      doCreate: 'projectTeam/form/doCreate',
      doFetchTeamMembers: 'teamMember/list/doFetch',
      doFetchProjectManagers: 'projectManager/list/doFetch',
    }),

    doCancel() {
      routerAsync().push('/project-team');
    },

    async doSubmit(payload) {
      if (this.isEditing) {
        return this.doUpdate(payload);
      } else {
        return this.doCreate(payload.values);
      }
    },

    async doFilterTeamMembers(payload) {
      const filter = this.setFilter(payload);

      return this.doFetchTeamMembers({
        filter,
      });
    },

    async doFilterProjectManagers(payload) {
      const filter = this.setFilter(payload);

      return this.doFetchProjectManagers({
        filter,
      });
    },

    setFilter(payload) {
      let id;
      if (payload != undefined || payload != null) {
        id = payload.map(function (f) {
          return f.id;
        });
      }

      return {
        projectTeam: this.projectTeamId,
        id,
      };
    },
  },
};
</script>

<style>
</style>
